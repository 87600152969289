import { z } from "zod";

import { Feature as ApiFeature } from "~data/Feature";

import { ContainsAllCheck } from "../../../utils";

export const Feature = z.enum([
  "cobrowse_whitelabel",
  "cobrowse_dev_console",
  "sso",
  "reporting",
  "activity_log",
  "cobrowse_multi_agent",
  "user_roles",
  "cobrowse_remote_control",
  "cobrowse_audio_calls",
  "cobrowse_agent_request_button",
  "cobrowse_dev_console_control",
  "cobrowse_session_history",
  "zapier_integration",
  "salesforce_integration",
  "intercom_session_summary",
  "cobrowse_agent_redirect",
  "cobrowse_agent_notes",
  "cobrowse_session_recording",
  "cobrowse_browsing_history",
  "cobrowse_browsing_history_automatic_screenshots",
  "cobrowse_user_full_screen",
  "cobrowse_agent_share_screen",
  "cobrowse_visitor_session_feedback",
  "cobrowse",
  "cobrowse_rest_api",
  "userview_screen_tv",
  "helloscreen_extension",
  "helloscreen_extension_full_screen",
  "helloscreen_extension_request_viewer_screen",
  "helloscreen_extension_multi_viewer",
  "helloscreen_extension_url_whitelist",
  "helloscreen_document_share",
  "helloscreen_document_share_templates",
  "livedocument_templates",
  "cobrowse_session_history_own",
]);

export type Feature = z.infer<typeof Feature>;

const check: ContainsAllCheck<Feature, ApiFeature> = true;

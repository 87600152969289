import { z } from "zod";

import { Url } from "../models/partials/Url";
import { VisitorModel } from "../models/Visitor";
import { ErrorResponse, SubscriptionLimitResponse, SuccessResponse } from "./standard-responses";
export const helloScreenEndpoints = {
  "hs.extension.join": (p: { sessionCode: string }) => ["hs", "post", `/extension/${p.sessionCode}/join`],
  "hs.publicLink.join": () => ["hs", "post", `/helloscreen_public_link`],
  "hs.authToken.exchange": () => ["hs", "post", "/embed/auth"],
  "hs.visitorsList.token": () => ["hs", "get", `/list_token`],
  "hs.stats": () => ["hs", "get", `/stats`],
  "hs.cobrowse.token": (p: { visitorId: string }) => ["hs", "post", `/w/${p.visitorId}/token`],
  "hs.cobrowse.feedback": (p: { sessionId: string }) => ["hs", "post", `/agent_feedback/${p.sessionId}`],
  "hs.cobrowse.onPrem.token": (p: { teamUniqueIdentifier: string }) => [
    "hs",
    "post",
    `/onprem_redirect/${p.teamUniqueIdentifier}/token`,
  ],
  "hs.cobrowse.notes.show": () => ["hs", "get", `/notes`],
  "hs.cobrowse.notes.update": () => ["hs", "put", `/notes`],
} as const satisfies Record<string, (p: any) => ["hs", "get" | "delete" | "post" | "put", `/${string}`]>;

export const helloScreenRequests = {
  "hs.extension.join": z.object({
    sessionCode: z.string(),
  }),
  "hs.publicLink.join": z.object({
    accessCode: z.string(),
    subdomain: z.string(),
  }),
  "hs.authToken.exchange": z.object({
    code: z.string(),
  }),
  "hs.visitorsList.token": z.undefined(),
  "hs.stats": z.object({
    astronautMessage: z.boolean(),
  }),
  "hs.cobrowse.token": z.object({
    visitorId: z.string(),
    adminOverride: z.boolean().optional().nullable(),
    confirm: z.boolean().optional().nullable(),
    source: z.string().optional().nullable(),
    metadataToken: z.string().optional().nullable(),
  }),
  "hs.cobrowse.feedback": z.object({
    sessionId: z.string(),
    feedback: z.string().optional(),
    rating: z.number(),
  }),
  "hs.cobrowse.onPrem.token": z.object({
    teamUniqueIdentifier: z.string(),
    visitorId: z.string(),
    confirm: z.boolean().optional().nullable(),
    metadataToken: z.string().optional().nullable(),
  }),
  "hs.cobrowse.notes.show": z.object({
    sessionId: z.string(),
  }),
  "hs.cobrowse.notes.update": z.object({
    sessionId: z.string(),
    notes: z.string().nullable(),
  }),
} as const;

export const helloScreenResponses = {
  "hs.extension.join": SuccessResponse.extend({
    token: z.string(),
    region: z.string(),
    shortId: z.string(),
    isOnboarding: z.boolean(),
  }).or(ErrorResponse.extend({ error: z.literal("invalid_session_code") })),
  "hs.publicLink.join": SuccessResponse.extend({
    token: z.string(),
    region: z.string(),
    shortId: z.string(),
  })
    .or(ErrorResponse.extend({ error: z.literal("invalid_access_code") }))
    .or(ErrorResponse.extend({ error: z.literal("invalid_subdomain") })),
  "hs.authToken.exchange": SuccessResponse.extend({
    token: z.string(),
  }).or(ErrorResponse.extend({ error: z.literal("invalid_code") })),
  "hs.visitorsList.token": SuccessResponse.extend({
    token: z.string(),
    endpoints: z.string().array(),
    lookupCodeNeeded: z.boolean(),
    requestAgentButtonEnabled: z.boolean(),
    onPremise: z.boolean(),
    onPremiseIdentifier: z.string().optional().nullable(),
  }).or(ErrorResponse.extend({ error: z.literal("no_teams") })),
  "hs.stats": SuccessResponse.extend({
    stats: z.object({
      online: z.number().nullable(),
      totalSessions: z.number(),
      mySessions: z.number(),
    }),
    astronautMessage: z.string().nullable().optional(),
  }),
  "hs.cobrowse.token": SuccessResponse.extend({
    askForRating: z.boolean(),
    confettiEnabled: z.boolean(),
    sessionId: z.string().nullable(),
    token: z.string(),
    region: z.string(),
    shortId: z.string(),
    notesEnabled: z.boolean(),
    teamId: z.custom<`t_${string}`>(),
  }).or(
    z
      .union([
        SuccessResponse.extend({ alternativeVisitorId: z.string(), teamId: z.custom<`t_${string}`>() }),
        ErrorResponse.extend({ error: z.literal("connection_error") }),
        ErrorResponse.extend({ error: z.literal("record_not_found") }),
        ErrorResponse.extend({ error: z.literal("visitor:not_supported") }),
        ErrorResponse.extend({ error: z.literal("visitor:not_online") }),
        ErrorResponse.extend({ error: z.literal("auth:admin:override_needed"), visitor: VisitorModel }),
        ErrorResponse.extend({
          error: z.literal("auth:login"),
          loginUrl: Url,
        }),
        ErrorResponse.extend({
          error: z.literal("confirmation_needed"),
          visitor: VisitorModel,
          teamId: z.custom<`t_${string}`>(),
        }),
      ])
      .or(SubscriptionLimitResponse),
  ),
  "hs.cobrowse.feedback": SuccessResponse,
  "hs.cobrowse.onPrem.token": SuccessResponse.extend({
    redirectUrl: Url,
    sessionId: z.string(),
  }).or(
    z
      .union([
        ErrorResponse.extend({ error: z.literal("record_not_found") }),
        ErrorResponse.extend({
          error: z.literal("auth:login"),
          loginUrl: Url,
        }),
        ErrorResponse.extend({ error: z.literal("team:onpremise_not_supported") }),
        ErrorResponse.extend({ error: z.literal("confirmation_needed") }),
      ])
      .or(SubscriptionLimitResponse),
  ),
  "hs.cobrowse.notes.show": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
  "hs.cobrowse.notes.update": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
} as const;

import DataTypes from "~data";

import { ContainsAllCheck } from "../../utils";
import { Camelize } from "../../utils/types";

export function salesActivityTypeLabel(type: DataTypes["SalesActivities"]["activityType"]) {
  switch (type) {
    case "call":
      return "Call";
    case "email":
      return "Email";
    case "meeting":
      return "Meeting";
    case "stage_change":
      return "Stage Change";
    case "social_message_sent":
      return "Social media message sent";
    case "social_message_received":
      return "Social media message received";
    case "note":
      return "Note";
    case "attempted_call":
      return "Attempted call";
  }
}

export function salesActivityTypeVerb(type: DataTypes["SalesActivities"]["activityType"]): string {
  switch (type) {
    case "stage_change":
    case "note":
    case "attempted_call":
    case "social_message_sent":
    case "social_message_received":
      return "by";
    case "call":
    case "email":
      return "from";
    case "meeting":
      return "with";
  }
}

export function salesActivityCreationAllowed(type: DataTypes["SalesActivities"]["activityType"]): boolean {
  switch (type) {
    case "stage_change":
      return false;
    case "note":
    case "call":
    case "email":
    case "meeting":
    case "social_message_sent":
    case "social_message_received":
    case "attempted_call":
      return true;
  }
}

export const activityTypeKeys = [
  "call",
  "email",
  "meeting",
  "stageChange",
  "note",
  "socialMessageSent",
  "socialMessageReceived",
  "attemptedCall",
] as const;

const check: ContainsAllCheck<
  Camelize<DataTypes["SalesActivities"]["activityType"]>,
  (typeof activityTypeKeys)[number]
> = true;

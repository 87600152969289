import { z } from "zod";

import { CompanyBaseData } from "./Company";
import { Contact } from "./Contact";
import { SalesRating } from "./partials/SalesRating";
import { TeamBaseData } from "./Team";
import { UserBaseData } from "./User";

export const SalesOpportunity = z.object({
  id: z.custom<`U${string}`>(),
  company: CompanyBaseData,
  team: TeamBaseData.nullable(),
  salesExec: UserBaseData.nullable(),
  source: UserBaseData.nullable(),
  primaryContact: Contact.nullable(),
  health: SalesRating.nullable(),
  ranking: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  notes: z.string().nullable(),
  opportunityType: z.enum(["new_business", "renewal", "expansion", "upsale"]),
  stage: z.enum(["new", "qualified", "discovery", "proposal", "trial", "closed_won", "closed_lost", "disqualified"]),
  value: z.number(),
  expectedCloseDate: z.coerce.date(),
  actualCloseDate: z.coerce.date().nullable(),
  lastInteraction: z.coerce.date().nullable(),
  lastTouchpoint: z.coerce.date().nullable(),
  tasksDue: z.boolean(),
  followUpNeeded: z.boolean(),
  open: z.boolean(),
  snoozedUntil: z.coerce.date().nullable(),
  healthExpired: z.boolean(),
  questions: z.array(
    z.object({
      question: z.string(),
      answer: z.string().nullable(),
    }),
  ),
  createdAt: z.coerce.date(),
  type: z.literal("sales_opportunity"),
});

export type SalesOpportunity = z.infer<typeof SalesOpportunity>;

export const SalesOpportunityAdminSearchData = SalesOpportunity.pick({
  id: true,
  type: true,
  name: true,
  company: true,
  description: true,
  opportunityType: true,
  open: true,
  stage: true,
});

export type SalesOpportunityAdminSearchData = z.infer<typeof SalesOpportunityAdminSearchData>;

import { z } from "zod";

import { CompanyBaseData } from "./Company";
import { SalesRating } from "./partials/SalesRating";
import { SalesOpportunity } from "./SalesOpportunity";
import { UserBaseData } from "./User";

export const SalesActivity = z.object({
  id: z.string(),
  company: CompanyBaseData,
  user: UserBaseData,
  automated: z.boolean(),
  activityType: z.enum([
    "call",
    "email",
    "meeting",
    "stage_change",
    "note",
    "social_message_sent",
    "social_message_received",
    "attempted_call",
  ]),
  description: z.string().nullable(),
  salesOpportunity: SalesOpportunity.pick({ id: true, type: true }).nullable(),
  opportunityValue: z.number().nullable(),
  opportunityHealth: SalesRating.nullable(),
  createdAt: z.coerce.date(),
  editableUntil: z.coerce.date(),
  type: z.literal("sales_activity"),
});

export type SalesActivity = z.infer<typeof SalesActivity>;

import { z } from "zod";

import DataTypes from "~data";

import { CountryCode } from "../models/partials/CountryCode";
import { PaymentSourceData } from "../models/partials/PaymentSourceData";
import { Product } from "../models/partials/Product";
import { TaxInfo } from "../models/partials/TaxInfo";
import { Plan, PlanPeriod } from "../models/Plan";
import { TeamBaseData } from "../models/Team";
import { ErrorResponse, SuccessResponse } from "./standard-responses";

export const onboardingEndpoints = {
  "onboarding.install.status": (p: { teamDomain: string; teamUniqueIdentifier: string }) => [
    "up",
    "get",
    `/welcome/${p.teamDomain}/install-${p.teamUniqueIdentifier}/status`,
  ],
  "onboarding.bookCall.done": (p: { teamDomain: string }) => ["up", "post", `/welcome/${p.teamDomain}/book_call`],
  "onboarding.members.invite": (p: { teamDomain: string }) => ["up", "post", `/welcome/${p.teamDomain}/invite_team`],
  "onboarding.startTrial": (p: { teamDomain: string }) => ["up", "post", `/welcome/${p.teamDomain}/start_trial`],
  "onboarding.purchase.plans": (p: { teamDomain: string }) => ["up", "get", `/welcome/${p.teamDomain}/purchase/plans`],
  "onboarding.purchase.card.token": (p: { teamDomain: string }) => [
    "up",
    "get",
    `/welcome/${p.teamDomain}/purchase/card/token`,
  ],
  "onboarding.purchase.card.add": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/welcome/${p.teamDomain}/purchase/card`,
  ],
  "onboarding.purchase.billing.update": (p: { teamDomain: string }) => [
    "up",
    "post",
    `/welcome/${p.teamDomain}/purchase/billing`,
  ],
  "onboarding.purchase.plans.show": (p: { teamDomain: string; planId: string }) => [
    "up",
    "get",
    `/welcome/${p.teamDomain}/purchase/plans/${p.planId}`,
  ],
  "onboarding.purchase.plans.subscribe": (p: { teamDomain: string; planId: string }) => [
    "up",
    "post",
    `/welcome/${p.teamDomain}/purchase/plans/${p.planId}`,
  ],
} as const satisfies Record<string, (p: any) => ["up", "get" | "delete" | "post" | "put", `/${string}`]>;

export const onboardingRequests = {
  "onboarding.bookCall.done": z.object({
    teamDomain: z.string(),
  }),
  "onboarding.members.invite": z.object({
    teamDomain: z.string(),
    email: z.string(),
  }),
  "onboarding.install.status": z.object({
    teamDomain: z.string(),
    teamUniqueIdentifier: z.string(),
  }),
  "onboarding.startTrial": z.object({
    teamDomain: z.string(),
    product: Product,
  }),
  "onboarding.purchase.plans": z.object({
    teamDomain: z.string(),
  }),
  "onboarding.purchase.card.token": z.object({
    teamDomain: z.string(),
  }),
  "onboarding.purchase.card.add": z.object({
    teamDomain: z.string(),
    intentTokenId: z.string(),
  }),
  "onboarding.purchase.billing.update": z.object({
    teamDomain: z.string(),
    vatNumber: z.string().optional().nullable(),
    invoiceName: z.string().optional().nullable(),
    invoiceAddress: z.string().optional().nullable(),
    invoiceCountry: CountryCode.nullable(),
    invoiceZip: z.string().optional().nullable(),
    invoiceState: z.string().optional().nullable(),
    invoiceEmail: z.string().optional().nullable(),
  }),
  "onboarding.purchase.plans.show": z.object({
    teamDomain: z.string(),
    planId: z.string(),
    licenses: z.number().optional().nullable(),
    period: PlanPeriod.optional().nullable(),
  }),
  "onboarding.purchase.plans.subscribe": z.object({
    teamDomain: z.string(),
    planId: z.string(),
    licenses: z.number(),
    autoAdjustLicenses:
      z.custom<Extract<DataTypes["Teams"]["autoAdjustLicenses"], "off" | "increase" | "increase_and_decrease">>(),
    period: PlanPeriod,
  }),
} as const;

export const onboardingResponses = {
  "onboarding.bookCall.done": SuccessResponse,
  "onboarding.members.invite": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      inviteErrors: z.object({
        email: z.string(),
      }),
    }),
  ),
  "onboarding.install.status": SuccessResponse.extend({
    stillInstalled: z.boolean(),
    isInstalled: z.boolean(),
    installationUrl: z.string(),
    apiKey: z.string(),
  }).or(
    ErrorResponse.extend({
      error: z.enum(["record_not_found", "on_premise"]),
    }),
  ),
  "onboarding.startTrial": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("no_trial_available"),
    }),
  ),
  "onboarding.purchase.plans": z.union([
    ErrorResponse.extend({
      error: z.literal("subaccount"),
      billingOwner: TeamBaseData,
    }),
    ErrorResponse.extend({
      error: z.literal("no_changes_allowed"),
    }),
    SuccessResponse.extend({
      plans: Plan.array(),
      onTrial: z.boolean(),
      trialEndDate: z.coerce.date().nullable(),
      initialLicenseCount: z.number(),
      currency: z.enum(["usd", "gbp", "eur"]),
    }),
  ]),
  "onboarding.purchase.card.token": SuccessResponse.extend({
    intentToken: z.object({
      id: z.string(),
      clientSecret: z.string(),
    }),
    stripePublicKey: z.string(),
  }),
  "onboarding.purchase.card.add": SuccessResponse.extend({
    paymentSource: PaymentSourceData,
    taxInfo: TaxInfo,
  }).or(
    ErrorResponse.extend({
      error: z.literal("stripe_error"),
      stripeError: z.string(),
    }),
  ),
  "onboarding.purchase.billing.update": SuccessResponse.extend({
    taxInfo: TaxInfo,
  }).or(
    ErrorResponse.extend({
      error: z.literal("validation_error"),
      teamErrors: z.record(onboardingRequests["onboarding.purchase.billing.update"].keyof(), z.string()),
    }),
  ),
  "onboarding.purchase.plans.show": SuccessResponse.extend({
    plan: Plan,
    taxInfo: TaxInfo,
  }),
  "onboarding.purchase.plans.subscribe": SuccessResponse.or(
    ErrorResponse.extend({
      error: z.literal("stripe_error"),
      stripeError: z.string(),
    }),
  ),
} as const;

import { z } from "zod";

import { Url } from "../models/partials/Url";
import { VisitorModel } from "../models/Visitor";
import { ErrorResponse, SubscriptionLimitResponse, SuccessResponse } from "./standard-responses";
export const userViewEndpoints = {
  "uv.authToken.exchange": () => ["uv", "post", "/embed/auth"],
  "uv.visitorsList.token": () => ["uv", "get", `/list_token`],
  "uv.stats": () => ["uv", "get", `/stats`],
  "uv.cobrowse.token": (p: { visitorId: string }) => ["uv", "post", `/w/${p.visitorId}/token`],
  "uv.cobrowse.feedback": (p: { sessionId: string }) => ["uv", "post", `/agent_feedback/${p.sessionId}`],
  "uv.cobrowse.onPrem.token": (p: { teamUniqueIdentifier: string }) => [
    "uv",
    "post",
    `/onprem_redirect/${p.teamUniqueIdentifier}/token`,
  ],
  "uv.cobrowse.notes.show": () => ["uv", "get", `/notes`],
  "uv.cobrowse.notes.update": () => ["uv", "put", `/notes`],
} as const satisfies Record<string, (p: any) => ["uv", "get" | "delete" | "post" | "put", `/${string}`]>;

export const userViewRequests = {
  "uv.authToken.exchange": z.object({
    code: z.string(),
  }),
  "uv.visitorsList.token": z.undefined(),
  "uv.stats": z.object({
    astronautMessage: z.boolean(),
  }),
  "uv.cobrowse.token": z.object({
    visitorId: z.string(),
    adminOverride: z.boolean().optional().nullable(),
    confirm: z.boolean().optional().nullable(),
    source: z.string().optional().nullable(),
    metadataToken: z.string().optional().nullable(),
  }),
  "uv.cobrowse.feedback": z.object({
    sessionId: z.string(),
    feedback: z.string().optional(),
    rating: z.number(),
  }),
  "uv.cobrowse.onPrem.token": z.object({
    teamUniqueIdentifier: z.string(),
    visitorId: z.string(),
    confirm: z.boolean().optional().nullable(),
    metadataToken: z.string().optional().nullable(),
  }),
  "uv.cobrowse.notes.show": z.object({
    sessionId: z.string(),
  }),
  "uv.cobrowse.notes.update": z.object({
    sessionId: z.string(),
    notes: z.string().nullable(),
  }),
} as const;

export const userViewResponses = {
  "uv.authToken.exchange": SuccessResponse.extend({
    token: z.string(),
  }).or(ErrorResponse.extend({ error: z.literal("invalid_code") })),
  "uv.visitorsList.token": SuccessResponse.extend({
    token: z.string(),
    endpoints: z.string().array(),
    lookupCodeNeeded: z.boolean(),
    requestAgentButtonEnabled: z.boolean(),
    onPremise: z.boolean(),
    onPremiseIdentifier: z.string().optional().nullable(),
  }).or(ErrorResponse.extend({ error: z.literal("no_teams") })),
  "uv.stats": SuccessResponse.extend({
    stats: z.object({
      online: z.number().nullable(),
      totalSessions: z.number(),
      mySessions: z.number(),
    }),
    astronautMessage: z.string().nullable().optional(),
  }),
  "uv.cobrowse.token": SuccessResponse.extend({
    askForRating: z.boolean(),
    confettiEnabled: z.boolean(),
    sessionId: z.string().nullable(),
    token: z.string(),
    region: z.string(),
    shortId: z.string(),
    notesEnabled: z.boolean(),
    teamId: z.custom<`t_${string}`>(),
  }).or(
    z
      .union([
        SuccessResponse.extend({ alternativeVisitorId: z.string(), teamId: z.custom<`t_${string}`>() }),
        ErrorResponse.extend({ error: z.literal("connection_error") }),
        ErrorResponse.extend({ error: z.literal("record_not_found") }),
        ErrorResponse.extend({ error: z.literal("visitor:not_supported") }),
        ErrorResponse.extend({ error: z.literal("visitor:not_online") }),
        ErrorResponse.extend({ error: z.literal("auth:admin:override_needed"), visitor: VisitorModel }),
        ErrorResponse.extend({
          error: z.literal("auth:login"),
          loginUrl: Url,
        }),
        ErrorResponse.extend({
          error: z.literal("confirmation_needed"),
          visitor: VisitorModel,
          teamId: z.custom<`t_${string}`>(),
        }),
      ])
      .or(SubscriptionLimitResponse),
  ),
  "uv.cobrowse.feedback": SuccessResponse,
  "uv.cobrowse.onPrem.token": SuccessResponse.extend({
    redirectUrl: Url,
    sessionId: z.string(),
  }).or(
    z
      .union([
        ErrorResponse.extend({ error: z.literal("record_not_found") }),
        ErrorResponse.extend({
          error: z.literal("auth:login"),
          loginUrl: Url,
        }),
        ErrorResponse.extend({ error: z.literal("team:onpremise_not_supported") }),
        ErrorResponse.extend({ error: z.literal("confirmation_needed") }),
      ])
      .or(SubscriptionLimitResponse),
  ),
  "uv.cobrowse.notes.show": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
  "uv.cobrowse.notes.update": SuccessResponse.extend({
    notes: z.string().nullable(),
  }),
} as const;

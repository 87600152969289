import { z } from "zod";

import { AdminRole } from "./partials/AdminRole";

export const User = z.object({
  id: z.custom<`u_${string}`>(),
  fromOauth: z.boolean(),
  name: z.string(),
  email: z.string(),
  emailDomain: z.string(),
  phoneNumber: z.string().nullable(),
  username: z.string(),
  subscribedToEmails: z.boolean(),
  language: z.string().nullable(),
  settingLivedocumentViewNotification: z.enum([
    "daily_aggregate_summary",
    "daily_summary",
    "new_viewers",
    "new_views",
    "never",
  ]),
  type: z.literal("user"),
  isAdmin: z.boolean(),
  intercomHashedEmail: z.string().nullable(),
  intercomId: z.string().nullable(),
  password: z.boolean(),
  passwordEnableNeeded: z.boolean(),
  mfaEnabled: z.boolean(),
  deletedAt: z.coerce.date().nullable(),
  lastRequestAt: z.coerce.date().nullable(),
  lastUsedLicenseAt: z.coerce.date().nullable(),
  lastAttemptedCobrowsingSessionAt: z.coerce.date().nullable(),
  cobrowsingSessionsCount: z.number(),
  sourceIntegrationName: z.string().nullable(),
  seated: z.boolean(),
  adminRoles: AdminRole.array(),
  metadata: z.object({
    leftG2ReviewAt: z.string().optional(),
    confettiUnlocked: z.boolean().optional(),
    intercomUserId: z.string().optional(),
  }),
});

export type User = z.infer<typeof User>;

export const UserBaseData = User.pick({ name: true, email: true, type: true, id: true, deletedAt: true });
export type UserBaseData = z.infer<typeof UserBaseData>;

export const UserTeamManagementData = UserBaseData.merge(
  User.pick({
    lastRequestAt: true,
    lastUsedLicenseAt: true,
    lastAttemptedCobrowsingSessionAt: true,
    cobrowsingSessionsCount: true,
    sourceIntegrationName: true,
    password: true,
    mfaEnabled: true,
    seated: true,
  }),
);
export type UserTeamManagementData = z.infer<typeof UserTeamManagementData>;

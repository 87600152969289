import { useEffect } from "react";

export function useGoogleTagManager({
  enable,
  userId,
  baa,
}: {
  enable: boolean;
  userId?: string | null;
  baa?: boolean | null;
}) {
  const authLoaded = typeof userId !== "undefined";
  useEffect(() => {
    (window as any).userId = userId;
    (window as any).baa = baa;
    (window as any).gtmContext = "app";
  }, [userId, baa]);

  useEffect(() => {
    if (authLoaded && enable) addGoogleTagManager();
  }, [authLoaded, enable]);
}

function addGoogleTagManager() {
  if (process.env.NODE_ENV !== "production") return;
  const w = window as any;
  w["dataLayer"] = w["dataLayer"] || []; // eslint-disable-line @typescript-eslint/strict-boolean-expressions
  w["dataLayer"].push({ "gtm.start": new Date().getTime(), "event": "gtm.js" });
  var f = document.getElementsByTagName("script")[0]!,
    j = document.createElement("script"),
    dl = "dataLayer" != "dataLayer" ? "&l=" + "dataLayer" : "";
  j.async = true;
  j.src = "//www.googletagmanager.com/gtm.js?id=GTM-W33PJXB5" + dl;
  f.parentNode!.insertBefore(j, f);
}
